module.exports={
  "name": "bedrock",
  "version": "1.2.3",
  "description": "Static site generator to easily make HTML prototypes",
  "main": "gulpfile.js",
  "scripts": {
    "test": "echo \"Error: no test specified\" && exit 1"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/mono-company/bedrock.git"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/mono-company/bedrock/issues"
  },
  "homepage": "https://github.com/mono-company/bedrock#readme",
  "devDependencies": {
    "autoprefixer": "^6.0.3",
    "babel-preset-es2015": "^6.0.15",
    "babelify": "^7.2.0",
    "browser-sync": "^2.9.11",
    "browserify": "^12.0.1",
    "chalk": "^1.1.3",
    "clipboard": "^1.5.3",
    "codemirror": "^5.18.2",
    "del": "^2.0.2",
    "directory-tree": "^0.1.1",
    "event-stream": "^3.3.2",
    "express": "^4.13.3",
    "faker": "^3.0.1",
    "front-matter": "^2.0.5",
    "glob": "^5.0.15",
    "gulp": "^3.9.1",
    "gulp-cached": "^1.1.0",
    "gulp-changed": "^1.3.0",
    "gulp-data": "^1.2.0",
    "gulp-filter": "^3.0.1",
    "gulp-if": "^2.0.0",
    "gulp-jsbeautifier": "^1.0.1",
    "gulp-postcss": "^6.0.1",
    "gulp-pug": "^3.3.0",
    "gulp-rename": "^1.2.2",
    "gulp-replace": "^0.5.4",
    "gulp-sass": "^2.0.4",
    "gulp-sourcemaps": "^1.6.0",
    "gulp-util": "^3.0.7",
    "gulp-watch": "^4.3.5",
    "inquirer": "^0.11.0",
    "jquery": "^3.1.1",
    "js-beautify": "^1.5.10",
    "lodash": "^3.10.1",
    "marked": "^0.3.5",
    "mkdirp": "^0.5.1",
    "moment": "^2.10.6",
    "node-notifier": "^4.3.1",
    "portfinder": "^0.4.0",
    "postcss": "^5.0.10",
    "run-sequence": "^1.2.2",
    "vinyl-buffer": "^1.0.0",
    "vinyl-source-stream": "^1.1.0",
    "watchify": "^3.6.0"
  },
  "dependencies": {
    "pug": "^2.0.0-rc.3"
  }
}
